<template>
  <div class="row">
    <div
      class="col-lg-6 offset-lg-3"
      style="position: fixed; height: 100vh;"
    >
      <!-- <div style="height: 100px; width: 100px; background-color: blue; top: 0; left: 0; position: absolute;">
      </div>
      <div style="height: 100px; width: 100px; background-color: blue; bottom: 0; right: 0; position: absolute;">
      </div> -->
      <div
        style="position: absolute;
                left: 25%;
                top: 18vh;"
      >
        <img src="@/assets/images/elements/Rectangle.png">
      </div>
      <div
        style="position: absolute;
                left: 32%;
                top: 18vh;"
      >
        <img src="@/assets/images/elements/Rectangle-1.png">
      </div>
      <div
        style="position: absolute;
                right: 24%;
                bottom: 13vh;"
      >
        <img src="@/assets/images/elements/Rectangle-2.png">
      </div>
      <div
        style="position: absolute;
                right: 20%;
                bottom: 10vh;"
      >
        <img src="@/assets/images/elements/Rectangle-3.png">
      </div>
      <div style="position: fixed; left: 0; right: 0; margin-left: auto; margin-right: auto; top: 15px">
        <div class="auth-wrapper auth-v1 px-2">
          <div class="auth-inner py-2">

            <!-- Login v1 -->
            <b-card class="mb-0 pl-2 pr-2">
              <b-link class="brand-logo">
                <!-- <img
                  src="@/assets/petronas-logo.svg"
                  alt="Petronas Logo"
                  v-if="!isAerodyneDemo"
                > -->
                <img
                  src="https://myprism.biz/assets/new_prism_logo.png"
                  alt="Petronas Logo"
                  v-if="!isAerodyneDemo"
                >
                <img
                  src="@/assets/aerodyne-prism.png"
                  alt="Aerodyne Logo"
                  v-if="isAerodyneDemo"
                  style="width: 60px; height: auto;"
                >
              </b-link>
              <b-card-title class="mb-1">
                Sign In
              </b-card-title>
              <b-card-text class="mb-2">
                Please sign-in to your account to continue
              </b-card-text>
              <validation-observer
                ref="loginValidation"
                #default="{invalid}"
              >
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                >
                  <b-form-group
                    label-for="email"
                    label="Email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        name="login-email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                        autofocus
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="password">Password</label>
                      <b-link>
                        <small>Forgot Password?</small>
                      </b-link>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false:null"
                          name="login-password"
                          placeholder="Password"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      Remember Me
                    </b-form-checkbox>
                  </b-form-group>
                  <b-button
                    class="button-login"
                    variant="primary"
                    type="submit"
                    background-color="red"
                    block
                    :disabled="invalid"
                    @click="validationForm"
                  >
                    Login
                  </b-button>
                </b-form>
              </validation-observer>

              <!-- <b-card-text class="text-center mt-2">
                <span>New on our platform? </span>
                <router-link :to="{name:'register'}">
                  <span>Create an account</span>
                </router-link>
              </b-card-text> -->

            </b-card>
          <!-- /Login v1 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import axios from 'axios'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: 'mh1@mh.com',
      password: 'mh1@123',
      status: '',
      // validation rules
      required,
      email,
      isAerodyneDemo: false
    }
  },
  mounted () {
    console.log("MOUNTED!")
    const thisData = JSON.parse(localStorage.getItem('userData-Core'));
    console.log("UDCORE: ", thisData);
    const path = window.location.host
    if (path.indexOf("aerodyne") >= 0) this.isAerodyneDemo = true
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    userLogin(){
      const formData = new URLSearchParams()
      formData.append('email', this.userEmail)
      formData.append('password', this.password)
      this.$axios.post(`${this.$baseUrl}/login/`, formData).then(response => {
        console.log(response.data)
        if (response.data.code === 200){
          const userDetails = response.data.user
          const userRoles = [
            2,
          ]
          const userToken = response.data.token

          localStorage.setItem('userData-Core', JSON.stringify(userDetails))
          localStorage.setItem('userRole-Core', JSON.stringify(userRoles))
          localStorage.setItem('userToken-Core', JSON.stringify(userToken))

          console.log("USERDATA LOGIN: ", userDetails)

           const thisData = JSON.parse(localStorage.getItem('userData-Core'));
          console.log("UDCORE 2: ", thisData);

          this.$axios.get(`${this.$baseUrl}/organizations/${userDetails.organization}`).then(res => {
            console.log("USERDATA ORG: ", res.data)
            localStorage.setItem('userOrganization-Core', JSON.stringify(res.data))

            // eslint-disable-next-line no-unused-vars
            this.$router.replace(getHomeRouteForLoggedInUser(userRoles)).then(() => {
              const timeout = 2000
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Info`,
                  icon: "CoffeeIcon",
                  variant: "primary",
                  text: `Welcome back ${userDetails.username}`,
                },
              }, { timeout })
            }).catch(() => {
              console.log('all good')
            })
          });

        } else {
          // eslint-disable-next-line no-alert
          alert(response.data.value)
        }
      })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.userLogin()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.button-login {
  background: linear-gradient(46.62deg, #00AE9D 0%, #93F9B9 93.64%);
  border-radius: 5px;
  border-style: none;
}
</style>
